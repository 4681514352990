import { useEffect, useMemo } from "react"
import Router from "next/router"
import { useGetMyselfQuery, GQLMyself } from "generated/documentTypes"

interface UseUserProps {
  redirectTo?: string
}

interface UseUserPayload {
  user?: GQLMyself
  isLoading: boolean
}

export const useUser = ({ redirectTo }: UseUserProps): UseUserPayload => {
  const query = useGetMyselfQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  })

  const me = query.data?.me
  const user = useMemo<GQLMyself | undefined>(() => {
    if (!me) return undefined
    return { ...me }
  }, [me?.id, me?.email, (me?.roles ?? []).join("|")])

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || query.loading) return

    if (
      // If redirectTo is set, redirect if the user was not found.
      !!redirectTo &&
      !user
    ) {
      Router.push(redirectTo)
    }
  }, [user, redirectTo, query])

  return {
    user,
    isLoading: query.loading,
  }
}
