import { memoize } from "lodash"
import { getEnvString } from "utils/env"

type LogFunction = (...args: any[]) => void

export interface Logger {
  info: LogFunction
  warn: LogFunction
  error: LogFunction
  debug: LogFunction
  fork: (forkName: string) => Logger
  disable: () => void
  enable: () => void
}

const loggersDisabled = new Set<string>()
const disableCheckCache = new Map<string, boolean>()

type LoggerLevel = "debug" | "info" | "warn" | "error"
type ConsoleLevel = "log" | "info" | "error" | "warn"

function consoleMethod(
  methodName: ConsoleLevel,
  loggerName: string,
): LogFunction {
  if (typeof console === "undefined" || console[methodName] === undefined) {
    throw new Error(`console.${methodName} does not exist`)
  }

  function log(...args: any[]): void {
    if (disableCheckCache.get(loggerName)) return

    let isDisabled = false
    if (!disableCheckCache.has(loggerName)) {
      if (loggersDisabled.has("")) {
        isDisabled = true
      }
      const keys = loggerName.split("/")
      for (let i = 1; i <= keys.length; i++) {
        const name = keys.slice(0, i).join("/")
        if (isDisabled) {
          disableCheckCache.set(name, true)
        }

        if (loggersDisabled.has(name)) {
          isDisabled = true
          disableCheckCache.set(name, true)
        }
      }
    }
    if (!isDisabled) {
      console[methodName](loggerName, ...args)
    }
  }

  return log
}

const noop: LogFunction = () => {
  // do nothing
}

const LOGGER_LEVELS: LoggerLevel[] = ["debug", "info", "warn", "error"]

const LOGGER_LEVEL_TO_CONSOLE_METHOD: Record<LoggerLevel, ConsoleLevel> = {
  debug: "log",
  info: "info",
  warn: "warn",
  error: "error",
}

function consoleMethodIfProd(
  level: LoggerLevel,
  loggerName: string,
): LogFunction {
  const loggerLevelEnabled = getEnvString("LOGGER_LEVEL").trim().toLowerCase()
  let loggerLevelNum = LOGGER_LEVELS.indexOf(loggerLevelEnabled as LoggerLevel)
  if (loggerLevelNum < 0) loggerLevelNum = Infinity
  const methodLevelNum = LOGGER_LEVELS.indexOf(level) ?? -Infinity
  const isLoggerEnabled = methodLevelNum >= loggerLevelNum

  if (!isLoggerEnabled) {
    return noop
  }

  return consoleMethod(LOGGER_LEVEL_TO_CONSOLE_METHOD[level], loggerName)
}

function consoleMethodResolver(method: string, logger: string): string {
  return `${logger}.${method}`
}

const consoleMethodIfProdMem = memoize(
  consoleMethodIfProd,
  consoleMethodResolver,
)

function resetDisabledCache(loggerName: string): void {
  for (const key of disableCheckCache.keys()) {
    if (
      key === loggerName ||
      key.startsWith(`${loggerName}/`) ||
      loggerName === ""
    ) {
      disableCheckCache.delete(key)
    }
  }
}

export const Logger = memoize((name: string): Logger => {
  return {
    info: consoleMethodIfProdMem("info", name),
    warn: consoleMethodIfProdMem("warn", name),
    error: consoleMethodIfProdMem("error", name),
    debug: consoleMethodIfProdMem("debug", name),
    fork: (name2) => Logger(`${name}/${name2}`),
    disable: () => {
      loggersDisabled.add(name)
      resetDisabledCache(name)
    },
    enable: () => {
      loggersDisabled.delete(name)
      resetDisabledCache(name)
    },
  }
})

export function disableRootLogger(): void {
  loggersDisabled.add("")
  resetDisabledCache("")
}

export function enableRootLogger(): void {
  loggersDisabled.delete("")
  resetDisabledCache("")
}

export function disableLogger(loggerName: string): void {
  loggersDisabled.add(loggerName)
  resetDisabledCache(loggerName)
}

export function enableLogger(loggerName: string): void {
  loggersDisabled.delete(loggerName)
  resetDisabledCache(loggerName)
}
